<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" class="text-justify">
        <v-img
          :src="require('@/assets/welcome/small mailbox.jpg')"
          max-height="325"
          height="350"
        ></v-img>
        <h1 class="text-center mb-6 mt-3">How does it work?</h1>
        <p>
          Upon Receiving the request from you, either via our online form or the faxed version, myKardio will send your patient by courier a Holter Kit with everything they need to easily self apply our state-of-the-art Patch Holter.
        </p><p>
          After the test is complete, the patient simply drops the holter in its return postage paid envelope in a mailbox, or if need we can arrange to have Xpresspost pick up the envelope at their residence.
        </p>
      </v-col>

      <v-col cols="12" sm="6" class="text-justify">
        <v-img
          :src="require('@/assets/ohip card.jpg')"
        ></v-img>
        <h1 class="text-center mb-6 mt-3">Is it covered by OHIP?</h1>
        <p>
          Yes, the myKardio Patch Holter test is covered by OHIP for every Ontarian who has coverage. If your patient isnot covered by OHIP you can still request a myKardio Patch but we need payment will for the service which may be covered the patient's private insurer.
        </p><p>
          In such case we will either need payment in advance from either the patient or your office if you are to bill the patient.
          <br>
          Pricing starts at $220 for the 72 hour test.
        </p>
      </v-col>
      
      <v-col cols="12" sm="6" class="text-justify">
        <v-img
          :src="require('@/assets/welcome/blue body ted heart.jpg')"
        ></v-img>
        <h1 class="text-center mb-6 mt-3">myKardio's part</h1>
        <p>
          Upon receipt of the Holter from the patient, one of myKardio's cardiac technologist will review the data and produce a preliminary report. This report is then reviewed and interpreted by one of our affiliated Cardiologist . The reports are then sent to requesting and family physicians via fax and hospital report manager platform usually within a business day of the holter receipt.
        </p>
      </v-col>

      <v-col cols="12" sm="6" class="text-justify">
        <v-img
          contain
          :src="require('@/assets/textlogo.png')"
        ></v-img>

        <v-row><v-spacer></v-spacer>
        <v-list class="pr-6">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>info@myKardio.ca</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>1-833-4KARDIO</v-list-item-title>
              <v-list-item-subtitle>1 (833) 452-7346</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-message-processing</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>1-833-4KARDIO</v-list-item-title>
              <v-list-item-subtitle>1 (833) 452-7346</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-fax</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>1-833-4KARDIO</v-list-item-title>
              <v-list-item-subtitle>1 (833) 452-7346</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Welcome',
  data () {
    return {
      items: [
        { src: require('@/assets/welcome/On chest.png') },
        { src: require('@/assets/welcome/heart pule blue - centered.jpg') },
        { src: require('@/assets/welcome/ekg-heart-wave_42077-631.jpg') },
        { src: require('@/assets/welcome/small mailbox.jpg') },
        { src: require('@/assets/welcome/arrhythmia.jpg') },
        { src: require('@/assets/welcome/Holter wet.png') },
        { src: require('@/assets/welcome/blue body ted heart.jpg') },
      ],
    }
  },
}
</script>